<template>
  <div id="cart" class="content-view cart-screen">
    <div v-if="!cart.length" class="text-center pt-3">Il carrello è vuoto</div>

    <div v-else>
      <!-- lista prodotti -->
      <div class="products mb-3">
        <div class="table-line-row">
          <div class="name">
            <b>Prodotto</b>
          </div>
          <div class="price">
            <b>Totale</b>
          </div>
        </div>

        <div
          class="item table-line-row"
          v-for="(line, index) in cart"
          :key="index"
        >
          <div class="name">
            <span class="text">{{ line.name }}</span> x {{ line.quantity }} -
            <a href="#" class="text-primary" @click="removeCart(line.id)"
              >Rimuovi</a
            >
          </div>
          <div class="price">
            {{ formatPrice(line.price * line.quantity) }}
          </div>
        </div>
      </div>

      <!-- totale -->
      <div
        class="total-row bg-primary text-white radius-20 table-line-row mb-3"
      >
        <div class="name">
          <b>Totale</b>
        </div>
        <div class="price">
          <b>{{ formatPrice(totalPrice) }}</b>
        </div>
      </div>

      <!-- payment -->
      <div v-if="!hasCustomerBillingData()">
        <div class="alert alert-warning" role="alert">
          Per procedere al pagamento è necessario inserire tutti i dati di
          fatturazione
        </div>
        <router-link
          :to="'/account/fatturazione/'"
          class="btn btn-light w-100 radius-20"
        >
          Modifica informazioni
        </router-link>
      </div>

      <div v-else>
        <PaypalButton />
      </div>
    </div>
  </div>
</template>
<script>
//import { store } from '@/store';
import { mapGetters, mapActions } from "vuex";
import { Utils } from "@/inc/Utils";
import PaypalButton from "@/components/payments/PaypalButton";

export default {
  name: "Carrello",
  mixins: [Utils],
  components: {
    PaypalButton,
  },
  data() {
    return {
      totalPrice: 0.0,
    };
  },
  computed: {
    ...mapGetters("product", ["cart"]),
    ...mapGetters("account", ["user"]),
  },
  methods: {
    ...mapActions("product", ["removeCart", "checkProductsAvailability"]),

    calcPrice() {
      this.cart.forEach((element) => {
        this.totalPrice +=
          parseFloat(element.price) * parseInt(element.quantity);
      });
    },
    /* checkout() {
      const vm = this;
      setTimeout(() => {
        vm.removeCart();
        alert("Purchase successful!");
        vm.$router.push("/");
      }, 2000);
    }, */
  },
  mounted() {
    this.checkProductsAvailability().then(() => {
      this.calcPrice();
    });
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
</style>
<template>
   <div>
      <div id="paypal-buttons">

         <div class="spinner pt-5 text-center">
            <div class="spinner-border" role="status">
               <span class="visually-hidden">Loading...</span>
            </div>
         </div>
            
      </div>

      <Errors :errors="errors" />

   </div>
</template>

<script scoped>
import { Utils } from '@/inc/Utils';
import { Settings } from '@/inc/Variables';
import Errors from '@/components/Errors.vue';
import { loadScript } from "@paypal/paypal-js";
import jQuery from 'jquery'
import { mapGetters, mapActions } from "vuex";

export default {
   name: "PaypalButton",
   mixins : [ Utils ],
   components: {Errors},
   data () {
      return {
         spinner: '',
         errors: [],
         clientID: Settings.payments.paypal.client_id,
         currency: Settings.payments.currency
         
      };
   },

   computed: {
      ...mapGetters("product", ["cart"]),
   },

   methods: {

      ...mapActions("product", ["removeCart"]),

      getTotal() {
         var total = 0.0;
         this.cart.forEach((element) => {
            total += parseFloat(element.price) * parseInt(element.quantity);
         });
         return total;
      },
      getPaypalItems(){
         var vm = this;
         var list = [];
         this.cart.forEach((el) => {
            var obj = {};
            obj.name = el.name;
            obj.unit_amount = {
               currency_code: vm.currency,
               value: parseFloat(el.price),
               description: ''
            };
            obj.quantity = el.quantity;
            list.push(obj);
         });
         return list;
      }
   },

   mounted(){
      var vm = this;
      this.spinner = jQuery('#paypal-buttons').html();
      this.errors = [];

      loadScript({ 
         "client-id": this.clientID,
         "locale": "it_IT",
         "currency": this.currency,
         //debug: true
      })
      .then((paypal) => {

         // start to use the PayPal JS SDK script
         jQuery('#paypal-buttons .spinner').remove();

         paypal.Buttons({
            
            style: {
               shape: 'pill',
               layout: 'horizontal',
               label: 'buynow',
               tagline: false
            },
            
            createOrder: function(data, actions) {
               // This function sets up the details of the transaction, including the amount and line item details.
               var total = vm.getTotal();
               
               return actions.order.create({
                  purchase_units: [{
                     amount: {
                        value: total,
                        breakdown: {
                           item_total: {  /* Required when including the `items` array */
                              currency_code: vm.currency,
                              value: total
                           }
                        }
                     },
                     items: vm.getPaypalItems()
                  }]
               });
            },

            onApprove: function(data, actions) {
               // This function captures the funds from the transaction.
               return actions.order.capture().then(function(details) {
                  // This function shows a transaction success message to your buyer.
                  //console.log('Transaction completed by ' + details.payer.name.given_name);
                  jQuery('#paypal-buttons').html(vm.spinner);

                  vm.createOrder('paypal', details.id)
                  .then( (order) => {

                     vm.updateOrder( order.id, {
                        meta_data: [
                           {
                              key: 'paypal_status',
                              value: details.status.toLowerCase()
                           }
                        ]
                     });

                     vm.removeCart();
                     vm.$router.push("/pagamento-completato");
                  });
               });
            },

            onCancel: function (/* data */) {
               // Show a cancel page, or return to cart
               //console.log(data)
            },

            onError: function (/* err */) {
               // For example, redirect to a specific error page
               vm.errors = vm.errors.concat( 'Il pagamento non  è andato a buon fine' );
            }

         }).render('#paypal-buttons');

      })
      .catch((/* err */) => {
         this.errors = this.errors.concat( 'Paypal non può essere caricato. Controlla la tua connessione' );
      });
   },
}
</script>

<style>

</style>